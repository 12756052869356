import React, { createContext, useContext, useEffect, useState } from "react";
import { format, isSameDay } from "date-fns";
import { formentera_only as places } from "./places"

const BookingContext = createContext()

export const useBookingContext = () => {
    const context = useContext(BookingContext)
    if (context === undefined) {
        throw new Error("useBookingContext must be used within a BookingProvider");
    }
    return context;
}

export const BookingProvider = (props) => {
    const [loading, setLoading] = useState(false)
    const [idayvuelta, setIdayvuelta] = useState("iyv");
    const [date, setDate] = useState(undefined);
    const [range, setRange] = useState({ from: undefined, to: undefined })
    const [origin, setOrigin] = useState(places[0]);
    const [destinations, setDestinations] = useState(places[0].destinations)
    const [destination, setDestination] = useState(places[0].destinations[0]);
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [babies, setBabies] = useState(0);
    const [selectedOuboundTrip, setSelectedOutboundTrip] = useState()
    const [selectedReturnTrip, setSelectedReturnTrip] = useState()
    const [selectedBono, setSelectedBono] = useState()
    const [residente, setResidente] = useState(false)
    const [totals, setTotals] = useState({
        adultsTotal: 0,
        childrenTotal: 0,
        discount: 0,
        discountTotal: 0,
        total: 0
    });
    const [adultFare, setAdultFare] = useState(0)
    const [childFare, setChildFare] = useState(0)
    const [tripSearchResult, setTripSearchResult] = useState({
        outboundTrips: [],
        returnTrips: [],
        bonos: [],
        idayvuelta: "iyv",
        date: undefined,
        range: { from: undefined, to: undefined },
        origin: undefined,
        destination: undefined,
        adults: 0,
        children: 0,
        babies: 0
    })
    const [preBooking, setPreBooking] = useState()
    const [booking, setBooking] = useState()

    //Set "adultFare" and "childFare" everytime "idayvuelta", !!"selectedOutboundTrip" or "residente" changes
    useEffect(() => {
        if (tripSearchResult.idayvuelta === "other" || tripSearchResult.idayvuelta === "bf") {
            if (!selectedBono) return
            setAdultFare(selectedBono.tarifa.return_adult)
            setChildFare(selectedBono.tarifa.return_child)
            return
        }
        if (!selectedOuboundTrip || !tripSearchResult) return
        setAdultFare(tripSearchResult.idayvuelta === 'iyv' ? residente ? selectedOuboundTrip.details.tarifa.res_return_adult : selectedOuboundTrip.details.tarifa.return_adult : residente ? selectedOuboundTrip.details.tarifa.res_oneway_adult : selectedOuboundTrip.details.tarifa.oneway_adult)
        setChildFare(tripSearchResult.idayvuelta === 'iyv' ? residente ? selectedOuboundTrip.details.tarifa.res_return_child : selectedOuboundTrip.details.tarifa.return_child : residente ? selectedOuboundTrip.details.tarifa.res_oneway_child : selectedOuboundTrip.details.tarifa.oneway_child)
    }, [tripSearchResult, selectedOuboundTrip, selectedBono, residente])

    //Set "totals" everytime "adultFare", "childFare", "adults" or "children" changes
    useEffect(() => {
        if (!adultFare || !childFare || (!selectedOuboundTrip && !selectedBono) || !tripSearchResult) return;
        const adultsTotal = tripSearchResult.adults * adultFare
        const childrenTotal = tripSearchResult.children * childFare
        const discount = tripSearchResult.idayvuelta === "iyv" && selectedOuboundTrip && selectedReturnTrip && !residente ? !isSameDay(new Date(selectedOuboundTrip.date), new Date(selectedReturnTrip.date)) ? 0 : (selectedOuboundTrip?.details.discount || 0) : 0
        const discountTotal = (adultsTotal + childrenTotal) * discount / 100;
        setTotals({
            adultsTotal,
            childrenTotal,
            discount,
            discountTotal,
            total: adultsTotal + childrenTotal - discountTotal
        })
    }, [adultFare, childFare, tripSearchResult, selectedOuboundTrip, selectedBono, residente]) //eslint-disable-line

    useEffect(() => {
        if (!preBooking) return;
        const adultsTotal = preBooking.adults * preBooking.tarifa_adult
        const childrenTotal = preBooking.children * preBooking.tarifa_child
        const discount = preBooking.discount || 0
        const discountTotal = (adultsTotal + childrenTotal) * discount / 100;
        setTotals({
            adultsTotal,
            childrenTotal,
            discount,
            discountTotal,
            total: adultsTotal + childrenTotal - discountTotal
        })
    }, [preBooking])

    const webfind = async (host, language) => {
        setSelectedBono()
        setSelectedOutboundTrip()
        setSelectedReturnTrip()
        const outboundDate = date ? format(date, "y-MM-dd") : range.from ? format(range.from, "y-MM-dd") : undefined;
        const returnDate = range.to ? format(range.to, "y-MM-dd") : undefined
        const url = `${host}/webfind?origin=${origin.code}&destination=${destination.code}&lang=${language}&date=${outboundDate}${returnDate ? `&returndate=${returnDate}` : ""}${idayvuelta === "other" ? "&bonosonly=true" : ""}`
        try {
            setLoading(true)
            const response = await fetch(url)
            if (response.status !== 200) return { errorCode: "bad-response" }
            const data = await response.json()
            if (data.errorCode === "OK") {
                setTripSearchResult({
                    outboundTrips: data.outboundTrips,
                    returnTrips: data.returnTrips,
                    bonos: data.bonos?.filter(b => idayvuelta === "other" ? !b.formentera : b.formentera),
                    date,
                    range,
                    idayvuelta,
                    origin,
                    destination,
                    destinations,
                    adults,
                    children,
                    babies,
                    residente
                })
                sessionStorage.setItem("tripSearchResults", JSON.stringify({
                    outboundTrips: data.outboundTrips,
                    returnTrips: data.returnTrips,
                    bonos: data.bonos?.filter(b => idayvuelta === "other" ? !b.formentera : b.formentera),
                    date,
                    range,
                    idayvuelta,
                    origin,
                    destination,
                    destinations,
                    adults,
                    children,
                    babies,
                    residente
                }))
                return data
            } else return data
        } catch (err) {
            throw new Error("Unexpected response from backend");
        } finally { setLoading(false) }
    }

    const restoreSessionStoredTripSearchResults = () => {
        const sessionStoredTripSearchResults = sessionStorage.getItem("tripSearchResults")
        if (!sessionStoredTripSearchResults) return false;
        else {
            const parsedSessionStoredTripResults = JSON.parse(sessionStoredTripSearchResults)
            //console.log("REstoring tripSearchResults", parsedSessionStoredTripResults)
            setTripSearchResult({
                outboundTrips: parsedSessionStoredTripResults.outboundTrips || [],
                returnTrips: parsedSessionStoredTripResults.returnTrips || [],
                bonos: parsedSessionStoredTripResults.bonos || [],
                idayvuelta: parsedSessionStoredTripResults.idayvuelta,
                date: parsedSessionStoredTripResults.date && new Date(parsedSessionStoredTripResults.date),
                range: parsedSessionStoredTripResults.range?.from ? { from: new Date(parsedSessionStoredTripResults.range.from), to: new Date(parsedSessionStoredTripResults.range.to || parsedSessionStoredTripResults.range.from) } : { from: undefined, to: undefined },
                origin: parsedSessionStoredTripResults.origin,
                destination: parsedSessionStoredTripResults.destination,
                destinations: parsedSessionStoredTripResults.destinations,
                adults: parsedSessionStoredTripResults.adults,
                children: parsedSessionStoredTripResults.children,
                babies: parsedSessionStoredTripResults.babies
            })
            setResidente(parsedSessionStoredTripResults.residente)
            return true;
        }
    }

    const restoreSessionStoredSelectedTrips = () => {
        const sessionStoredSelectedTrips = sessionStorage.getItem("selectedTrips")
        if (!sessionStoredSelectedTrips) return false
        else {
            const parsedSessionStoredSelectedTrips = JSON.parse(sessionStoredSelectedTrips)
            //console.log("REstoring selectedTrips", parsedSessionStoredSelectedTrips)
            setSelectedOutboundTrip(parsedSessionStoredSelectedTrips.selectedOuboundTrip)
            setSelectedReturnTrip(parsedSessionStoredSelectedTrips.selectedReturnTrip)
            setSelectedBono(parsedSessionStoredSelectedTrips.selectedBono)
            return true
        }
    }

    return (
        <BookingContext.Provider
            value={
                {
                    loading,
                    setLoading,
                    idayvuelta,
                    setIdayvuelta,
                    date,
                    setDate,
                    range,
                    setRange,
                    origin,
                    setOrigin,
                    destinations,
                    destination,
                    setDestination,
                    setDestinations,
                    adults,
                    setAdults,
                    children,
                    setChildren,
                    babies,
                    setBabies,
                    selectedOuboundTrip,
                    setSelectedOutboundTrip,
                    selectedReturnTrip,
                    setSelectedReturnTrip,
                    selectedBono,
                    setSelectedBono,
                    residente,
                    setResidente,
                    totals,
                    adultFare,
                    childFare,
                    webfind,
                    tripSearchResult,
                    setTripSearchResult,
                    restoreSessionStoredTripSearchResults,
                    restoreSessionStoredSelectedTrips,
                    preBooking,
                    setPreBooking,
                    booking,
                    setBooking
                }
            }>
            {props.children}
        </BookingContext.Provider>
    );
};